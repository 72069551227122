<template>
  <div :class="['select', { _open: isOpen }]">
    <div
      :class="['__selection', { _active: selected !== '' }]"
      @click="toggleSelect"
    >
      <label>
        {{ label }}
      </label>
      <Input v-model="selected" type="text" />
      <Icon type="arrow" />
    </div>
    <div class="select-options">
      <div
        v-for="opt in options"
        :key="opt.value"
        :class="['__item', { _selected: selected === opt }]"
        @click="changeOption(opt)"
      >
        {{ opt.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
      selected: "",
    };
  },
  created() {
    const onClickOutside = (e) =>
      (this.isOpen = this.$el.contains(e.target) && this.isOpen);
    document.addEventListener("click", onClickOutside);
    this.$on("hook:beforeDestroy", () =>
      document.removeEventListener("click", onClickOutside)
    );
  },
  methods: {
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
    changeOption(opt) {
      this.selected = opt.name;
      this.isOpen = false;
      this.$emit("change", this.name, opt);
    },
  },
};
</script>
