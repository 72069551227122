import info from "./info";
import text from "./text";
import faq from "./faq";
import formStep from "./form-steps";
import politic from "./politic";
import popupForm from "./popup-form";

export default {
  ...info,
  ...text,
  faq,
  formStep,
  ...politic,
  ...popupForm,
};
