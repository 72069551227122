export default {
  popupFormSelects: [
    {
      name: "sum",
      label: "Ваша общая сумма долга",
      list: [
        {
          value: "<500k",
          name: "До 500 тыс",
        },
        {
          value: "500k-1m",
          name: "500 тыс - 1 млн",
        },
        {
          value: "1m-2m",
          name: "1 млн - 2 млн",
        },
        {
          value: ">2m",
          name: "Более 2 млн",
        },
      ],
    },
    {
      name: "mortgage",
      label: "Есть ли жилье в ипотеке",
      list: [
        {
          value: "yes",
          name: "Да",
        },
        {
          value: "no",
          name: "Нет",
        },
      ],
    },
    {
      name: "region",
      label: "Ваш региональный центр",
      list: [
        {
          value: "NN",
          name: "Нижний Новгород",
        },
        {
          value: "Mos",
          name: "Москва",
        },
        {
          value: "SP",
          name: "Санкт-Петербург",
        },
        {
          value: "Tv",
          name: "Тверь",
        },
      ],
    },
  ],
  popupBtn: "Заказать консультацию",
  popupBtnClose: "Ок",
  popupAgree: "Отправляя заявку, Вы соглашаетесь с",
  popupAgreeLink: "условиями передачи персональных данных",
};
