<template>
  <div class="popup__wrapper">
    <div class="popup">
      <div class="__close" @click="closePopup">
        <Icon type="close" />
      </div>
      <template v-if="!successFormPopup.value">
        <div class="__title">{{ title }}</div>
        <div class="__text text-black-50">{{ text }}</div>
      </template>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  inject: ["closePopup", "successFormPopup"],
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
};
</script>
