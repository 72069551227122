<template>
  <div class="icon-container">
    <div :class="['icon', type && '_type_' + type, to && '_to_' + to]" />
  </div>
</template>

<script>
export default {
  name: "Icon",
  props: {
    type: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
