export default {
  headerText: "{bold} В любом регионе России",
  headerTextBold: "Списание долгов законно.",
  btnText1: "Обратный звонок",
  btnText2: "Бесплатная консультация",
  mainTitle: "Законное списание долгов",
  mainSubtitle: "Гарантия результата",
  mainText: "Оставьте заявку для получения индивидуальной схемы банкроства",
  mainTextList: "Вы вправе списать следующие долги:",
  faqTitle: "Популярные вопросы о банкротстве граждан",
  mainList: [
    {
      title: "Кредиты",
      desc: "Остановим неправомерные действия со стороны кредиторов и детально разъясним Ваши права",
    },
    {
      title: "Микрозаймы",
      desc: "Рассчитаем реальную сумму долга, аннулируем пени, начисления и штрафы",
    },
    {
      title: "Поручительства",
      desc: "Избавим от звонков с угрозами даже в самых сложных ситуациях",
    },
    {
      title: "Долги по ЖКХ",
      desc: "Проведём полный анализ и составим подробный план действий",
    },
    {
      title: "Долговые расписки",
      desc: "Избавим от звонков с угрозами даже в самых сложных ситуациях",
    },
    {
      title: "Налоги и сборы",
      desc: "Спишем задолженность по  налогам и сборам в ФНС, ФПС",
    },
  ],
  formTitle: "Узнайте стоимость процедуры именно в Вашем случае",
  formText: "ответив всего на 4 вопроса",
  formSuccessTitle: "Ваша заявка успешно отправлена",
  formSuccessText: "В ближайшее время с Вами свяжется наш оператор.",
  btnTextNext: "Продолжить",
  infoTitle:
    "Списание долгов через процедуру банкротства – {break}единственный способ законно списать долги без потери имущества!",
  infoText:
    "1 октября 2015 г. вступил в силу Федеральный закон №127 «О несостоятельности (банкротстве)» в отношении физических лиц. Процедура банкротства длится около 6-8 месяцев, в зависимости от сложности дела и количества кредитов.",
  repercussionTitle: "Последствия банкротства",
  repercussion: {
    plus: [
      "Освобождение от уплаты кредитов, долгов, штрафов",
      "Снятие ограничений на выезд за границу",
      "У Вас появляется возможность оформить новые кредиты",
      "Банкротство не влияет на трудоустройство",
      "Вас перестают беспокоить коллекторы и приставы",
    ],
    minus: [
      "В течение пяти лет Вы обязаны указывать на факт банкротства при оформлении займа",
      "В течение трёх лет Вы не можете занимать должность директора и быть учредителем",
      "В течение пяти лет нельзя повторно проводить процедуру банкротства",
    ],
  },
  footerLogoText: "Списание долгов законно. {break}В любом регионе России",
  popupTitle: "Оставить заявку на бесплатную консультацию",
  popupText: "Оставьте заявку для получения индивидуальной схемы банкроства",
  formValidationText: "Поле обязательно",
  formValidationSelect: "Заполните все пунты",
  formValidationRadio: "Выберите пункт",
  formValidationPhone: "В номере должно быть 11 цифр",
  errorMessage: "Что-то пошло не так, попробуйте еще раз",
};
