import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
      import(/* webpackPrefetch: true */ "@/views/index/index.vue"),
    meta: {
      layout: "layout-default",
    },
  },
  {
    path: "/politic",
    name: "Politic",
    component: () =>
      import(/* webpackPrefetch: true */ "@/views/politic/politic.vue"),
    meta: {
      layout: "layout-default",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
