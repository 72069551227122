import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "./app.vue";
import router from "./router";
import UiKit from "@/uikit/index";
import Layouts from "@/layouts";
import ru from "@/texts/ru/index";

Vue.config.productionTip = false;

Vue.use(UiKit);
Vue.use(Layouts);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "ru",
  messages: {
    ru,
  },
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
