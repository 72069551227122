export default [
  {
    name: "amount",
    title: "Сколько кредитов у Вас во всех банках?",
    listRadio: [
      {
        value: "1-3",
        name: "1 - 3",
      },
      {
        value: "4-5",
        name: "4 - 5",
      },
      {
        value: ">5",
        name: "Более 5-ти",
      },
    ],
  },
  {
    name: "sum",
    title: "Сумма долгов в рублях",
    listRadio: [
      {
        value: "<500k",
        name: "До 500 тыс",
      },
      {
        value: "500k-1m",
        name: "500 тыс - 1 млн",
      },
      {
        value: "1m-2m",
        name: "1 млн - 2 млн",
      },
      {
        value: ">2m",
        name: "Более 2 млн",
      },
    ],
  },
  {
    name: "expiration",
    title: "Есть ли просрочки",
    listRadio: [
      {
        value: "no",
        name: "Нет просрочек",
      },
      {
        value: "1-10 day",
        name: "1 - 10 дней",
      },
      {
        value: "1 month",
        name: "1 месяц",
      },
      {
        value: "> 1 mouth",
        name: "Более 1-го месяца",
      },
    ],
  },
  {
    title: "Контактная информация",
    text: "Оставьте свой номер и мы проконсультируем прямо сейчас <b>бесплатно!</b> Не пропустите звонок.",
    btnText: "Отправить заявку",
  },
];
