// eslint-disable-next-line prefer-destructuring
const headID = document.getElementsByTagName("head")[0];

const link = document.createElement("link");

link.type = "text/css";
link.rel = "stylesheet";
link.href =
  "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap";

headID.appendChild(link);
