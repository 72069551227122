/* eslint-disable */
import "./atoms/init-css/rest.scss";
import "./atoms/init-css/grid.scss";
import "./atoms/layout.scss";

import "./atoms/typography/typography.js";
import "./atoms/typography/typography.scss";

import Icon from "./icon/icon.vue";
import "./icon/icon.scss";
import Button from "./button/button.vue";
import "./button/button.scss";
import Input from "./input/input.vue";
import "./input/input.scss";
import Select from "./select/select.vue";
import "./select/select.scss";
import Popup from "./popup/popup.vue";
import "./popup/popup.scss";

export default {
  install: function (Vue) {
    Vue.component("Icon", Icon);
    Vue.component("Button", Button);
    Vue.component("Input", Input);
    Vue.component("Select", Select);
    Vue.component("Popup", Popup);
  },
};
