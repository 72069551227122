<template>
  <div class="layout-default">
    <div class="layout-max1200__wrapper header__wrappper">
      <Header class="layout-max1200" />
    </div>
    <main class="layout-max1200__wrapper">
      <slot />
    </main>
    <div class="layout-max1200__wrapper footer__wrapper">
      <Footer class="layout-max1200" />
    </div>
    <Popup
      v-if="isPopupActive"
      :title="$t('popupTitle')"
      :text="$t('popupText')"
    >
      <PopupForm
        :request="request"
        :error-message="errorMessage"
        @submit="submitFormPopup"
      />
    </Popup>
    <div v-if="mobileBtn" class="btn-mobile">
      <Button @click="openPopup">
        {{ $t("btnText2") }}
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutDefault",
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "layout-components" */ "@/components/header/header.vue"
      ),
    Footer: () =>
      import(
        /* webpackChunkName: "layout-components" */ "@/components/footer/footer.vue"
      ),
    PopupForm: () =>
      import(
        /* webpackChunkName: "layout-components" */ "@/components/popup-form/popup-form.vue"
      ),
  },
  provide() {
    return {
      openPopup: this.openPopup,
      closePopup: this.closePopup,
      successFormPopup: this.successFormPopup,
    };
  },
  data() {
    return {
      isPopupActive: false,
      mobileBtn: false,
      successFormPopup: {
        value: false,
      },
      request: false,
      errorMessage: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.mobileBtnShow);
    window.addEventListener("resize", this.mobileBtnShow);
  },
  methods: {
    openPopup() {
      this.isPopupActive = true;
      document.body.style.overflow = "hidden";
    },
    closePopup() {
      this.isPopupActive = false;
      document.body.style.overflow = "";
    },
    mobileBtnShow() {
      this.mobileBtn = window.innerWidth < 768 && window.scrollY > 240;
    },
    submitFormPopup(params) {
      if (this.request) {
        return;
      }
      this.request = true;
      this.errorMessage = null;

      fetch(process.env.VUE_APP_EMAIL_SERVER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(params),
      })
        .then((res) => {
          if (res.status === 200) {
            this.successFormPopup.value = true;
            return res;
          }
          this.request = false;
          throw new Error(res);
        })
        .catch((e) => {
          this.request = false;
          this.errorMessage = this.$t("errorMessage");
          console.error(e);
          throw e;
        });
    },
  },
};
</script>
