export default [
  {
    title: "Это законно?",
    desc: "Да, процедура абсолютно законна. Возможность банкротства предоставляется физическим лицам с 2015 года, согласно Закону №127-ФЗ «О несостоятельности (банкротстве)».",
  },
  {
    title: "Сколько это стоит?",
    desc: `Стоимость услуг рассчитывается индивидуально, так как зависит от нескольких факторов: суммы долга, количества кредиторов, наличия имущества в собственности. <br /> 
    Обязательные платежи: 
    <ul>
      <li>300 руб. — госпошлина.</li>
      <li>25 000 руб. — вознаграждение финансовому управляющему за проведение процедуры</li>
      <li>15 000 руб. — оплата публикации сведения о банкротстве</li>
    </ul>`,
  },
  {
    title: "Какие долги можно списать?",
    desc: `<ul>
      <li>Долги по кредитным картам</li>
      <li>Потребительские кредиты</li>
      <li>Долги по ЖКХ</li>
      <li>Автокредиты</li>
      <li>Микрозаймы</li>
      <li>Займы у физических лиц</li>
    </ul>`,
  },
  {
    title: "У меня заберут квартиру?",
    desc: "Если это единственное жилье, то никто не вправе её забрать (при условии, что эта квартира не находится в ипотеке). Но мы сделаем все возможное для законного сохранения и остального имущества.",
  },
  {
    title: "Какие последствия признания банкротом? ",
    desc: `<ul>
      <li>С вас будут списаны все долги (кредиты, ЖКХ, долги физ. лицам, налоги)</li>
      <li>Вы сможете выезжать за границу, приобретать имущество, совершать сделки, пользоваться банковскими счетами. В общем, жить так, как у вас и не было долгов вовсе, за небольшими ограничениями.</li>
      <li>Вы не сможете занимать руководящие должности в финансовой сфере и быть предпринимателем в течение 3-х лет.</li>
      <li>При обращении в финансовую организацию нужно будет сообщать о факте банкротства.</li>
    </ul>`,
  },
];
