<template>
  <input
    ref="input"
    :value="val"
    class="input"
    :class="[
      '_type_' + styleType,
      '_input-type_' + type,
      '_input-clear_' + (value ? 'no' : 'yes'),
    ]"
    :name="name"
    :type="type === 'sdate' ? 'text' : type"
    :max="max"
    :min="min"
    :pattern="pattern"
    :inputmode="inputmode"
    :autocapitalize="autocapitalize"
    @input="(evt) => (val = evt.target.value)"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @click="$emit('click')"
    @change="$emit('change', val)"
  />
</template>

<script>
import {
  servicePhoneMask,
  checPositionAfterMask,
  setNumberMask,
  setCapitalizeMask,
} from "./services/mask/mask";

export default {
  name: "Input",
  props: [
    "name",
    "value",
    "type",
    "max",
    "min",
    "validationMessage",
    "maxlength",
    "pattern",
    "inputmode",
    "mask",
    "autocapitalize",
  ],
  computed: {
    styleType() {
      return (
        {
          number: "text",
          tel: "text",
          email: "text",
          date: "text",
          sdate: "text",
        }[this.type] || this.type
      );
    },
    val: {
      get() {
        if (this.value && this.maxlength) {
          return this.value.slice(0, this.maxlength);
        }

        if (this.type === "tel") {
          const maskPhone = servicePhoneMask(this.value);

          return maskPhone.valueInput;
        }

        return this.value;
      },
      set(value) {
        if (value && this.maxlength) {
          const result = value.slice(0, this.maxlength);
          this.$emit("input", result);
          this.$refs.input.value = result;
          return;
        }

        if (this.type === "tel") {
          const maskPhone = servicePhoneMask(value);
          const oldCaretPos = this.$refs.input.selectionStart;

          this._setDataInInput({
            value,
            oldCaretPos,
            result: maskPhone.result,
            valueInput: maskPhone.valueInput,
          });
          return;
        }

        if (this.autocapitalize === "on") {
          const maskResult = setCapitalizeMask(value);
          const oldCaretPos = this.$refs.input.selectionStart;

          this._setDataInInput({
            value,
            oldCaretPos,
            result: maskResult,
            valueInput: maskResult,
          });
          return;
        }

        if ((this.mask || "").indexOf("#") !== -1) {
          const maskResult = setNumberMask(value, this.mask);
          const oldCaretPos = this.$refs.input.selectionStart;

          this._setDataInInput({
            value,
            oldCaretPos,
            /*
            тут не надо делать всякие .replace(/ /g, "") 
            тк кроме редактирования есть и отображения
            */
            result: maskResult,
            valueInput: maskResult,
          });
          return;
        }

        this.$emit("input", value);
      },
    },
  },
  watch: {
    validationMessage: {
      handler: "onValidationMessageChange",
    },
  },
  mounted() {
    this.onValidationMessageChange(this.validationMessage);
  },
  methods: {
    _setDataInInput({ value, oldCaretPos, result, valueInput }) {
      this.$emit("input", result);

      this.$nextTick(() => {
        const newCaretPos = checPositionAfterMask(
          value,
          valueInput,
          oldCaretPos
        );

        this.$refs.input.value = valueInput;
        this.$refs.input.setSelectionRange(newCaretPos, newCaretPos);
        this._checkNextFocusByNameGroup(newCaretPos);
      });
    },
    onValidationMessageChange(validationMessage) {
      this.$refs.input.setCustomValidity(validationMessage || "");
      this.$refs.input.checkValidity();
    },
    _checkNextFocusByNameGroup(newCaretPos) {
      if ((this.mask || "").length === 0) {
        return;
      }

      const [fieldName, fieldIndex] = (this.name || "").split("-");

      if (this.mask.length <= newCaretPos && fieldName && fieldIndex) {
        const nextSibling = document.querySelector(
          `input[name=${fieldName}-${parseInt(fieldIndex, 10) + 1}]`
        );

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    },
  },
};
</script>
